
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        bottom: 0;
        position: absolute;
        background-color: #143e55;
        z-index: -1;
    }
    p {
        color: #fff;
        font-size: 11px;
        font-weight: 300;
        @include lg {
            font-size: 12px;
        }
    }
    ul {
        color: #fff;
        font-size: 10px;

        @include lg {
            font-size: 12px;
        }
    }
}
