
            @import 'src/styles/mixins.scss';
        
.mainSectionStyle {
    position: relative;
    padding-bottom: 20px;
    @include lg {
        padding-bottom: 39px;
    }
    overflow: scroll;
}
.titleWrapper {
    width: 100%;
    position: fixed;
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 14.3px 0px rgba(0, 0, 0, 0.1);
}
.mainModalTitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    @include lg {
        font-size: 26px;
    }
}
.mainModalDescription {
    color: #939393;
    font-size: 14px;
    text-align: center;
    @include lg {
        font-size: 16px;
    }
}

.mainHeaderTitle {
    margin-top: 130px;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0px 50px 0px 28px;
    width: 550px;
    @include sm {
        width: unset;
    }

    span {
        font-size: 16px;
        font-weight: 600;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 8px;
        @include lg {
            padding-bottom: 12px;
            font-size: 20px;
            min-width: 145px;
        }
        &:first-of-type {
            min-width: 220px;
            @include lg {
                min-width: 240px;
                max-width: 240px;
            }
        }
    }
}

.rowStyle {
    padding: 0 50px 0 28px;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 550px;
    @include sm {
        width: unset;
    }
    @include lg {
        padding: 0 50px 0 28px;
    }

    span {
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include lg {
            min-width: 145px;
        }
        &:first-of-type {
            padding-top: 8px;
            padding-bottom: 8px;
            min-width: 220px;
            font-size: 14px;
            justify-content: start;
            @include lg {
                padding-top: 12px;
                padding-bottom: 12px;
                min-width: 240px;

                font-size: 18px;
            }
        }
    }
}

.secure-plus {
    span:nth-of-type(2) {
        background-color: #f1f1f1;
    }
}
.superior {
    span:nth-of-type(3) {
        background-color: #f1f1f1;
    }
}
.supreme {
    span:nth-of-type(4) {
        background-color: #f1f1f1;
    }
}
