
            @import 'src/styles/mixins.scss';
        
.animation {
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    background: linear-gradient(183deg, #ffaf5c -21.68%, #ff870b 97.79%);
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
