
            @import 'src/styles/mixins.scss';
        
.container {
    @apply text-center mb-10 mt-28 lg:mt-36;

    .content {
        @apply text-center flex flex-col-reverse items-center gap-10;

        @include lg {
            @apply flex-row justify-between;
        }
    }

    .title {
        @apply text-3xl font-bold  mb-4;
        @include lg {
            @apply text-4xl;
        }
    }

    .description {
        @apply text-base text-[#777777] mb-10;
        @include lg {
            @apply text-lg mb-20;
        }
    }

    .mainImageContainer {
        @apply relative;

        button {
            @apply text-white bg-[#1c435d] rounded-full flex items-center justify-center absolute transition-all duration-300 ease-in-out;

            width: 6%;
            max-width: 40px;

            aspect-ratio: 1/1;

            svg {
                width: 70%;
            }

            &:hover {
                @apply scale-110;
            }
        }

        .active {
            @apply bg-white border border-[#ff870b];

            svg path {
                stroke: #ff870b;
            }
        }
    }

    .cards {
        @apply flex flex-col items-center gap-4;
        width: min(100%, 350px);

        .card {
            width: min(100%, 350px);

            min-height: 332px;
            @include lg {
                min-height: 432px;
            }
            @apply bg-[#1C435D] text-white p-8 rounded-3xl border-b-8 border-[#ff870b] flex flex-col
                gap-2 items-center;

            .cardTitle {
                @apply text-2xl font-[#ff870b] text-[#FF870B] font-bold;
                @include lg {
                    @apply text-3xl mt-3;
                }
            }

            .cardDescription {
                @apply text-base font-light mb-4;
            }

            .cardPrice {
                @apply text-4xl font-bold;
            }

            .cardImageContainer {
                @apply flex items-center justify-center;
                img {
                    @apply w-auto h-20 max-w-40;
                    @include lg {
                        @apply w-auto h-28 max-w-60;
                    }
                }
            }
        }
    }
}
