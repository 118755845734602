
            @import 'src/styles/mixins.scss';
        
.embla {
    // max-width: 30rem;
    // margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 350px;
}
.embla__viewport {
    overflow: hidden;
    height: 430px;
}
.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    align-items: stretch;
}
.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    padding-left: var(--slide-spacing);
    transition: translate 0.3s ease;
    width: 350px;
    cursor: grab;
}
.embla__controls {
    display: grid;
    place-items: center;
    // grid-template-columns: auto 1fr;
    // justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}
.embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
}
.embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
}
.embla__button:disabled {
    color: var(--detail-high-contrast);
}
.embla__button__svg {
    width: 35%;
    height: 35%;
}
.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
}
.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
