
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    position: relative;
    border-radius: 20px;
    border-bottom: 7px solid #ff870b;
    background: #fff;
    box-shadow: 0px 4px 4.5px 0px rgba(0, 0, 0, 0.4);
    max-width: 392px;
    padding: 34px 20px 100px;
    @include lg {
        padding: 47px 34px 121px;
    }
    // display: flex;
    // flex-direction: column;
    // min-height: 335px;
}
.buttonStyle {
    position: absolute;
    width: 85%;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    button {
        padding-left: 0;
        padding-right: 0;
    }
    @include lg {
        width: 80%;
        bottom: 41px;
    }
}
.featuresStyle {
    height: 200px;
    // border: 1px solid red;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    @include lg {
        height: 244px;
        margin-top: 26px;
    }
    // ul {
    //     border: 1px solid green;
    // }
    li {
        // width: 50%;
        // height: 20px;
        // border: 1px solid blue;
        display: flex;
        align-items: center;
        gap: 5px;
        @include lg {
            gap: 10px;
        }
    }
}
.bg-style {
    position: relative;
    // margin-bottom: 100px;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        // background-color: #00445f;
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/assets/images/enduranceProtectionPlansImgMobile.jpg");
        @include lg {
            background-image: url("/assets/images/enduranceProtectionPlansImgDesktop.jpg");
        }
    }
}
.slide {
    padding: 15px 10px;
    @include lg {
        padding: 18px 0;
    }
}
