
            @import 'src/styles/mixins.scss';
        
.embla {
    margin: auto;
    --slide-height: 100%;
    --slide-spacing: 30px;
    --slide-size: 100%;
    --dot-select-color: #ff870b;
    --dot-unselect-color: #fff;
}
.embla__viewport {
    overflow: hidden;
}
.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}
.embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
    margin-top: 40px;
    gap: 10px;
}
.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--dot-unselect-color);
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot--selected {
    background-color: var(--dot-select-color);
}
