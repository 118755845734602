
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
    font-style: italic;
}
.main-banner {
    // display: flex;
    // justify-content: flex-start;
    position: relative;
    // align-items: flex-start;
    // height: auto;
    // height: calc(100vh - 58px);
    // border: 1px solid red;
    // min-height: calc(100vh - 58px);
    margin-top: -2px;

    .form {
        background-color: #fff;
        box-shadow: 0px 7px 6.3px 0px rgba(0, 0, 0, 0.1);
        z-index: 5;
        width: 100%;
        padding: 40px 25px 66px;
        max-width: 600px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        // margin-top: 34px;
        display: flex;
        flex-direction: column;
        // margin-bottom: 100px;
        position: relative;
        border-radius: 15px;
        @include lg {
            min-width: 300px;
            // width: 50%;
            padding: 50px 42px 30px;
            // margin-bottom: 200px;
            // margin-top: 86px;
        }
        .steps-inner {
            flex-grow: 1;
            padding-bottom: 20px;
        }
        h1 {
            &.step-title {
                font-size: 20px !important;
                font-weight: 800 !important;
                margin: 0;
                @include lg {
                    font-size: 24px !important;
                    // line-height: 1.3;
                }
            }
        }
        .step-title {
            text-align: left;
            font-size: 20px !important;
            font-weight: 800 !important;
            margin-bottom: 10px;
            color: $nextautowarranty-denim-blue !important;
            color: black !important;
            @include lg {
                font-size: 24px !important;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 500 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                margin-right: -50px;
                margin-left: -50px;
            }
        }
        .step-description {
            text-align: left;
            font-weight: 400;
            margin-bottom: 2rem;
            font-size: 16px;
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            transition: all 0.2s ease-in-out;
            color: #fff !important;
            border-radius: 5px;
            background-color: #ff870b !important;
            box-shadow: 0px 4px 7.8px 0px rgba(0, 0, 0, 0.25);
            font-weight: 700 !important;
            svg {
                path {
                    fill: #fff;
                }
            }

            &:hover {
                @apply shadow;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            // padding-left: 10px;
            // padding-right: 10px;
            label {
                // margin-left: 7px !important;
                font-weight: 700;
            }
            // @include lg {
            //     // max-width: 450px;
            // }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: rgba(
                            $nextautowarranty-denim-blue,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $nextautowarranty-denim-blue;
                    // background-color: $nextautowarranty-denim-blue;
                    color: $nextautowarranty-denim-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    @include md {
                        min-height: 64px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 45%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: rgba(
                            $nextautowarranty-denim-blue,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $nextautowarranty-denim-blue;
                    color: $nextautowarranty-denim-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .select-field {
            border-radius: 5px;
            border: 1px solid #d9d9d9;
        }
        // .field-note {
        //     margin-left: 7px;
        // }
        // .field-error-message {
        //     left: 7px;
        // }
        // .field {
        //     &:not(.radio-field) {
        //         // margin: 0 -10px;
        //         border-radius: 40px;
        //         background-color: white;
        //     }
        // }
        .tcpa {
            margin-bottom: 10px;
        }
    }
    // .image {
    //     z-index: 2;
    //     & > div {
    //         position: absolute !important;
    //         left: 0;
    //         bottom: 0;
    //         height: 300px;
    //         width: 100vw;
    //         max-width: unset !important;
    //         left: 50% !important;
    //         transform: translateX(-50%) !important;

    //         @include lg {
    //             height: 100%;
    //             top: 0 !important;
    //             left: 0%;
    //         }
    //     }

    //     img {
    //         min-height: unset !important;
    //         min-width: unset !important;
    //         width: auto !important;
    //         height: 100% !important;
    //         max-width: unset !important;
    //         margin-left: 0 !important;
    //         margin-right: auto !important;
    //     }
    // }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        // background-position: right;
        z-index: -2;
        background-image: url("/assets/images/mainImgMobile.jpg");
        @include lg {
            background-image: url("/assets/images/mainImgDesktop.jpg");
        }
    }
}
.step-block:nth-of-type(1) {
    .form-field {
        label {
            font-size: 14px;
            font-weight: 700;
        }
    }
}
.bellowBanner {
    align-items: center;
    background-color: #003957;
    max-width: 600px;
    gap: 10px;
    padding: 40px 17px 20px;
    border-radius: 8px;
    color: #fff;
    z-index: 1000;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 11px;
    font-style: italic;
    @include lg {
        margin-bottom: 96px;
        font-size: 14px;
    }
}
